<template>
    <div class="icon-polygon-down">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="7" viewBox="0 0 14 7" fill="none">
            <path d="M7.35684 6.84236C7.16085 7.04205 6.83915 7.04205 6.64316 6.84237L0.9063 0.997346C0.595847 0.68104 0.819935 0.147109 1.26314 0.147109L12.7369 0.147109C13.1801 0.147109 13.4042 0.681039 13.0937 0.997345L7.35684 6.84236Z" fill="white" />
        </svg>
    </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
    .icon-polygon-down {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);

        :hover {
            color: white;
            cursor: pointer;
        }
    }
</style>